/* You can add global styles to this file, and also import other style files */
html {
    position: relative;
    min-height: 100%;
}

body {
  /* Defines margin-bottom for the footer */
  margin: 0;
  min-width: 320px;
}

* {
  outline: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}

svg {
  fill: #00bfa5;
}

span.leftHeaderContainer > a.topMenuButton > span,
span.logo > a > span,
span.leftHeaderContainer > button.topMenuButton > span,
span.rightHeaderContainer > span.topMenuActionsContainer > span > a.topMenuActionButton > span,
span.rightHeaderContainer > span.signInContainer > a.desktopSignInButton > span > span,
span.sidenavMenu > a.sidenavMenuButton > span,
span.sidenavMenu > button.sidenavMenuButton > span {
  font-weight: bold;
}

span.leftHeaderContainer > a.topMenuButton.pro,
span.sidenavMenu > a.sidenavMenuButton.pro {
  color: #304ffe;
}

input.mat-mdc-input-element.mat-mdc-form-field-input-control.mdc-text-field__input {
  font-size: 16px;
}

.ctaMode input.mat-mdc-input-element.mat-mdc-form-field-input-control.mdc-text-field__input {
  font-size: 48px;
  height: 68px;
  color: #304ffe;
  padding: 0 16px;
  width: calc(100% - 2* 16px);
}

.mat-mdc-text-field-wrapper {
  height: 48px;
}

.ctaMode .mat-mdc-form-field-flex {
  height: 100px;
}

.mat-mdc-form-field-flex {
  height: 48px;
}

.searchSettings .mat-mdc-form-field-infix {
  width: unset
}

.sortSetting .mat-mdc-form-field-infix {
  width: 100px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  height: 48px;
  min-height: 48px;
  line-height: 16px;
}

.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  transform: translateY(-75%);
}

.mat-mdc-chip > span.mdc-evolution-chip__cell.mdc-evolution-chip__cell--primary > span {
  cursor: pointer;
}

.relatedLandingPageChipSet, .relatedLandingPageChipSet > div {
  justify-content: center;
}

/* This permits the correct display of autocomplete predictions over the settings menu overlay */
.cdk-overlay-container {
  z-index: 20000!important;
}


.searchSettings .mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline .mdc-notched-outline__notch {
  max-width: unset;
}

.qc-cmp-persistent-link {
  display: none!important;
}

.show-qc-cmp-persistent-link {
  background-color: #00bfa5!important;
}

.rateCard > mat-expansion-panel > .mat-expansion-panel-content > .mat-expansion-panel-body {
  display: flex;
  flex-wrap: wrap;
  font-size: 16px;
}

.rateCard > mat-expansion-panel > .mat-expansion-panel-content > .mat-expansion-panel-body > span {
  text-wrap: wrap;
  flex-grow: 1;
  flex-basis: 300px;
  padding-right: 16px;
}

.rateCard > mat-expansion-panel > .mat-expansion-panel-header > .mat-expansion-panel-body > ul > li {
  margin-bottom: 16px;
}
/* 
.info-snackbar.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #304ffe;
  color: white;
  margin-bottom: 16px;
}
.info-snackbar.mat-mdc-snack-bar-container button {
  background-color: white;
  color: #304ffe;
  border: none;
}

.warn-snackbar.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #FFA726;
  color: white;
  margin-bottom: 16px;
}
.warn-snackbar.mat-mdc-snack-bar-container button {
  background-color: white;
  color: #FFA726;
  border: none;
}

.error-snackbar.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #D14081;
  color: white;
  margin-bottom: 16px;
}
.error-snackbar.mat-mdc-snack-bar-container button {
  background-color: white;
  color: #D14081;
  border: none;
} */

.mat-error {
  background-color: #D14081!important;
  color: #fff!important
}

.mat-error[disabled] {
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.category > span.mdc-list-item__primary-text, .category > span.mdc-list-item__primary-text > span {
  font-weight: bold;
}

.subCategory > span.mdc-list-item__primary-text > span {
  padding-left: 16px;
}

.galleryViewList > div {
  height: 100%;
}

mat-grid-tile-footer.galleryNavigationContainer {
  height: 64px!important;
  width: 100%;
  margin-top: min(50vh, 600px);
  left: unset!important;
  right: unset!important;
  background: unset!important;
}

.customHighlight {
  pointer-events: none;
}

.customTooltip {
  font-size: 16px;
}

.introjs-overlay {
  pointer-events: none;
}

.customTooltip .introjs-tooltip-title {
  color: #304ffe;
  font-size: 18px;
}

.mdc-button .mdc-button__label {
  z-index: unset!important;
}

@media only screen and (max-width: 900px) {
  .ctaMode .mat-mdc-form-field-flex {
    height: 80px;
  }
  .ctaMode input.mat-mdc-input-element.mat-mdc-form-field-input-control.mdc-text-field__input {
    font-size: 36px;
    height: unset;
    text-align: center;
  }

}